import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { AppBar, Toolbar, IconButton, Box, Container, Drawer, List, ListItem, ListItemText, Divider, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';

const Logo = styled('img')({
  background: 'white',
  borderRadius: '50%',
  height: '50px',
});

const NavigationLink = styled(NavLink)(({ theme }) => ({
  color: '#fff',
  textDecoration: 'none',
  '&.active': {
    fontWeight: 'bold',
    color: theme.palette.primary.light,
  },
  '&:hover': {
    color: theme.palette.primary.light,
  },
}));

const DrawerContent = styled(Box)(({ theme }) => ({
  width: '80vw',
  minHeight: '100vh',
  backgroundColor: '#1a1a1a',
  color: '#fff',
  padding: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  boxShadow: `0px 4px 20px rgba(0, 0, 0, 0.7)`,
  overflowY: 'auto', // Allows scrolling on shorter screens
 paddingTop: 100,
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(2),
  right: theme.spacing(2),
  color: '#fff',
  zIndex: 1001,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const NavItem = styled(ListItem)(({ theme }) => ({
  padding: theme.spacing(2),
  transition: 'transform 0.3s, color 0.3s',
  '&:hover': {
    transform: 'scale(1.05)',
    color: theme.palette.primary.light,
  },
  paddingTop: 5,
}));

const DrawerTitle = styled(Typography)({
  marginBottom: '20px',
  fontSize: '1.5rem',
  fontWeight: 'bold',
  color: '#fff',
});

const NavigationBar = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [showNavbar, setShowNavbar] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > lastScrollY && window.scrollY > 50) {
        setShowNavbar(false); // Hide navbar on scroll down
      } else {
        setShowNavbar(true); // Show navbar on scroll up
      }
      setLastScrollY(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollY]);

  const drawer = (
    <DrawerContent>
      <CloseButton
        edge="start"
        color="inherit"
        aria-label="close"
        onClick={handleDrawerToggle}
      >
        <CloseIcon />
      </CloseButton>
      <DrawerTitle>Navigate</DrawerTitle>
      <List sx={{ width: '100%' }}>
        {['Home', 'Services', 'Contact', 'Payments', 'About', 'Reviews'].map((text, index) => (
          <React.Fragment key={text}>
            <NavItem button component={NavigationLink} to={`/${text.toLowerCase()}`} onClick={handleDrawerToggle}>
              <ListItemText primary={text} />
            </NavItem>
            {index < 6 && <Divider sx={{ backgroundColor: '#444' }} />}
          </React.Fragment>
        ))}
      </List>
    </DrawerContent>
  );

  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor: 'transparent',
        boxShadow: 'none',
        transition: 'transform 0.3s ease-in-out',
        transform: showNavbar ? 'translateY(0)' : 'translateY(-100%)', // Slide navbar in and out
      }}
    >
      <Container maxWidth="lg">
        <Toolbar disableGutters sx={{ justifyContent: 'space-between' }}>
          <NavLink to="/" >
            <Logo src="logo4.png" alt="Logo" />
          </NavLink>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleDrawerToggle}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </Container>
      <Drawer
        anchor="right"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        transitionDuration={700}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          '& .MuiDrawer-paper': {
            backgroundColor: 'rgba(0, 0, 0, 0.9)',
            backdropFilter: 'blur(10px)',
            overflowY: 'auto', // Allow scrolling in case of overflow
          },
        }}
      >
        {drawer}
      </Drawer>
    </AppBar>
  );
};

export default NavigationBar;
