import React, { Suspense, lazy } from 'react';
import NavigationBar from './components/Navbar';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import ScrollToTop from './ScrollToTop';
import { Helmet } from 'react-helmet';
import { CssBaseline, ThemeProvider } from '@mui/material';
import theme from './theme';
import Loading from './components/Loading'; // Import the Loading component

const MainSection = lazy(() => import('./pages/MainSection'));
const Contact = lazy(() => import('./pages/Contact'));
const Services = lazy(() => import('./pages/Services'));
const AboutNealFrazier = lazy(() => import('./pages/AboutNealFrazier'));
const DonationTips = lazy(() => import('./components/DonationTips'));
const ReviewLinks = lazy(() => import('./pages/ReviewLinks'));
const Payments = lazy(() => import('./pages/Payments'));
const YouTubeMusicPlayer = lazy(() => import('./components/YouTubeMusicPlayer'));
const Footer = lazy(() => import('./components/Footer'));

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      
      <Router>
      <ScrollToTop />
        <Helmet>
          <title>Virginia Beach Hampton Roads VA Valet Ninjas - Premier Automated Valet Parking & Web Development</title>

          <meta name="description" content="Discover unparalleled solutions with Valet Ninjas, offering cutting-edge automated valet parking and web development services in Virginia Beach and Hampton Roads." />

          <meta name="keywords" content="Valet Ninjas, Automated Valet Parking, Web Development, Virginia Beach, Hampton Roads" />
        </Helmet>
       
        <NavigationBar />
       
        <div className="App">
          <Suspense fallback={<Loading />}>
            <Routes>
              <Route path="/*" element={<MainSection />} />
              <Route path="/" element={<MainSection />} />
              <Route path="/services" element={<Services />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/about" element={<AboutNealFrazier />} />
              <Route path="/donations" element={<DonationTips />} />
              <Route path="/payments" element={<Payments />} />
              <Route path="/reviews" element={<ReviewLinks />} />
            </Routes>
          </Suspense>
        </div>
        <Suspense fallback={<Loading />}>
          <YouTubeMusicPlayer videoId="kIQS15N5qYQ" /> {/* Add YouTube player */}
        </Suspense>
        <Suspense fallback={<Loading />}>
          <Footer />
        </Suspense>
      </Router>
    </ThemeProvider>
  );
};

export default App;
